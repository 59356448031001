import React from 'react'
import First from './First'
import Second from './Second'

const Gallery = () => {
  return (
    <>
      <First></First>
      <Second></Second>
    </>
  )
}

export default Gallery